





































































































import { Component, Prop, Watch } from 'vue-property-decorator';
import CompanyContactInformation from '@/components/company/CompanyContactInformation.vue';
import { mixins } from 'vue-class-component';
import VueRegisterStoreWidget from '@/utils/widgets/VueRegisterStoreWidget';
import VueBaseWidget from '@/utils/widgets/VueBaseWidget';
import WidgetHelper from '@/utils/helpers/widgets/WidgetHelper';
import ExhibitorUrl from '@/models/graphql/ExhibitorUrl';
import UrlType from '@/utils/enums/UrlType';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import { Location } from 'vue-router';
import EntityType from '@/utils/enums/EntityType';
import StatLoggerActions from '@/utils/enums/StatLoggerActions';
import StatLoggerCategories from '@/utils/enums/StatLoggerCategories';
import { buildQueryDefinition } from '@/graphql/_Tools/GqlQueryDefinition';
import GqlEntityFilterType from '@/utils/enums/gql/GqlEntityFilterType';
import { Data } from '@/utils/types/WidgetData';
import SliderArrowComponent from '@/components/SliderArrowComponent.vue';
import { Splide, SplideSlide } from '@splidejs/vue-splide';

@Component({
  components: {
    SliderArrowComponent,
    FontAwesomeComponent,
    CompanyContactInformation,
    Splide,
    SplideSlide,
  },
})
export default class CompanyWebsiteListWidget extends mixins(VueBaseWidget, VueRegisterStoreWidget) {
  @Prop({
    required: false,
    default: null,
  })
  private readonly title!: string;

  @Prop({
    required: false,
    default: null,
  })
  private readonly subtitle!: string;

  @Prop({
    required: false,
    default: () => [],
  })
  private readonly data!: ExhibitorUrl[];

  private entityCode: string | null = null;

  private isSlide = false;

  // eslint-disable-next-line class-methods-use-this
  private get options(): object {
    return {
      gap: 30,
      start: 0,
      perMove: 1,
      trimSpace: 'move',
      focus: 0,
      loop: true,
      rewind: true,
      pagination: false,
      cover: true,
    };
  }

  created(): void {
    if (this.payload
        && 'entityCode' in this.payload
        && this.payload.entityCode) {
      this.entityCode = WidgetHelper
        .entityCode(this.payload.entityCode as string, this.$route as unknown as Location);
      this.setDataConfig([{
        alias: this.storeName,
        operation: 'exhibitorUrl',
        gqlDefinition: buildQueryDefinition({
          filter: {
            type: GqlEntityFilterType.EXHIBITOR_URL_FILTER,
            value: {
              exhibitor: {
                uid: this.entityCode,
              },
              type: UrlType.SECONDARY_WEBSITE,
            },
          },
        }),
        fragmentName: 'exhibitorUrlBaseFragment',
      }]);
    } else if (this.widget && !this.standAlone) {
      this.setDataConfig(
        undefined,
        true,
        `Missing entityCode in payload for ${this.widget.type} widget with id ${this.widget.id}`,
      );
    }
  }

  @Watch('windowWidth', { deep: true })
  @Watch('data')
  updateSlideUI(): void {
    this.$nextTick(() => {
      if (this.$el && this.data && this.data.length) {
        const size = 350;
        // eslint-disable-next-line no-nested-ternary
        const padding = this.isMobile
          ? 24
          : this.isTablet
            ? 40 : 56;
        const items = this.data.length;
        const length = ((items - 1) * size) + ((items - 1) * 56) + (padding * 2);
        const slides = this.$el.getElementsByClassName('slides');
        if (slides && slides.length > 0) {
          this.isSlide = length >= slides[0].getBoundingClientRect().width;
        }
      }
    });
  }

  private onClick(component: Data, payload: Data): void {
    if (this.data[payload.index as number].url) {
      this.onWebsiteBoxClick(this.data[payload.index as number]);
    }
  }

  private nextClick(): void {
    const arrowNextEl = this.$el
      .querySelector(`.company-website-list-${this.widget.uid} .splide__arrow--next`) as HTMLElement;
    if (arrowNextEl) {
      arrowNextEl.click();
    }
  }

  private prevClick(): void {
    const arrowPrevEl = this.$el
      .querySelector(`.company-website-list-${this.widget.uid} .splide__arrow--prev`) as HTMLElement;
    if (arrowPrevEl) {
      arrowPrevEl.click();
    }
  }

  // eslint-disable-next-line class-methods-use-this
  addHttps(url: string): string {
    if (url && (!/^http?:\/\//i.test(url) && !/^https?:\/\//i.test(url))) {
      return `https://${url}`;
    }
    return url;
  }

  private onWebsiteBoxClick(url: ExhibitorUrl): void {
    if (this.entityCode && url.url && url.url.length > 0) {
      const siteUrl = this.addHttps(url.url);
      window.open(siteUrl, '_blank');
      this.$logger.logMatomoStats(
        this.authUser,
          this.community.code as string,
          EntityType.EXHIBITOR,
          StatLoggerActions.VIEW,
          siteUrl,
          -1,
          this.entityCode,
          StatLoggerCategories.SOCIAL_LINK,
          this.$i18n.locale,
      );
    }
  }
}
